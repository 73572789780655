<template>
   <div class="h-screen text-center grid grid-cols-1 gap-4 place-content-center">
      <MazLoader/>
   </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
   data() {
      return {
      }
   },

   computed: {
      ...mapGetters('auth', ['user'])
   },

   methods: {
      check_onboarding() {
         if (this.user.account.is_onboarded == 'false') {
            this.$router.push({ name: 'UserOnboarding' })
         } else {
           this.$router.push({ name: 'Dashboard'})
         }
      }
   },


   mounted() {
      setTimeout(() => {
         this.check_onboarding()
      }, 3000)
   }
}
</script>